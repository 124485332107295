.ns {
  display: flex;
  flex-wrap: wrap;
  margin: 10vh 0;
}

.ns-image img {
  max-width: 100%;
  width: 400px;
  height: auto;
  flex-direction: column;
  align-items: center;
  border-radius: 6px;
}
.ns .ns-image {
  width: 50%;
  text-align: right;
  padding-right: 40px;
}
.ns .ns-text {
  padding-left: 40px;
  width: 50%;
  align-self: center;
}
@media screen and (max-width: 770px) {
  .ns .ns-image {
    width: 100%;
    text-align: center;

    padding-right: 0px;
  }
  .ns .ns-text {
    padding-left: 0px;
    width: 100%;
    align-self: center;
    text-align: center;
  }
}
