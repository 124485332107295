@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("../fonts/Noto/stylesheet.css");
@import url("../fonts/IM/stylesheet.css");
@import url("NotSame.css");
@import url("Hero.css");
@import url("Faq.css");

:root {
  --font-family: "Noto Regular", sans-serif;
  /*Background*/

  --background: #07080d;
  --navbar-background: #07080d;

  --second-background: #07080d;
  --third-background: #0b152b;
  --white-background: #fdfdfd;

  /*Colors */

  --primary-color: #ebff00;
  --secondary-color: #5886ff;
  --warning-color: rgb(240, 160, 35);
  --dangerous-color: rgb(236, 0, 0);
  --white-text: #fff;
  --black-text: #000;
  --black: #000;

  /*Spacing and sizes*/
  --block-padding: 20px;
  --border-radius: 10px;
  --container-width: 1111px;
  --navbar-height: 120px;
  --navbar-height-m: 24px;

  /*Fonts size*/
  --p: 20px;
  --h1: 44px;
  --h2: 36px;
  --h3: 24px;
  --h4: 20px;
  --h5: 16px;
  --h6: 14px;
}

html,
body {
  padding: 0;
  margin: 0;
  color: white;
  font-family: var(--font-family);
}
p {
  line-height: 25px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-block: 0;
  font-family: "Noto Bold", sans-serif;
}
h1 {
  font-weight: 700;
  font-size: var(--h1);
}
h2 {
  font-weight: 600;
  font-size: var(--h2);
}
h3,
.h3 {
  font-weight: 600;
  font-size: var(--h3);
}

h4 {
  font-weight: 600;
  font-size: var(--h4);
}
h5 {
  font-weight: 600;
  font-size: var(--h5);
}
h6 {
  font-weight: 600;
  font-size: var(--h6);
}

p,
strong,
b {
  font-size: var(--p);
}
a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.section {
  max-width: var(--container-width);
  margin: 0px auto;
}
.section-container {
  padding: 40px 20px;
}
.primary-bg {
  background-color: var(--background);
}
.secondary-bg {
  background-color: var(--second-background);
}

.cta-bg {
  background-color: var(--primary-color);
}

/*HOMES IMAGES*/
@import url(Roadmap.css);

.heading {
  text-align: center;
  padding: 40px 0;
}

.logo-text {
  font-family: "IM";
  font-weight: bold;
  font-style: italic;
  color: #f4d35e;
  font-size: 60px;
}
.social-icons {
  display: inline-block;
  color: rgb(236, 218, 218);
}

@media screen and (max-width: 700px) {
  .social-icons {
    margin-top: 20px;
  }
}
.social-icons a,
.social-icons a:hover {
  margin: 40px;
  text-decoration: none;
  text-transform: none;
  font-size: 40px;
}
.social-icons a {
  color: rgb(255, 255, 255);
}

.social-icons a:hover {
  color: var(--primary-color);
}
