@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=IM+Fell+English+SC&display=swap);
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Noto Bold";
  src: url(/static/media/notosans-bold-webfont.7b2ff0f6.woff2) format("woff2"),
    url(/static/media/notosans-bold-webfont.751b2601.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Noto Regular";
  src: url(/static/media/notosans-regular-webfont.37f3c0a5.woff2) format("woff2"),
    url(/static/media/notosans-regular-webfont.468c2af3.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}

/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on October 20, 2021 */

@font-face {
  font-family: "IM";
  src: url(/static/media/imfellenglishsc-regular-webfont.3e37a892.woff2) format("woff2"),
    url(/static/media/imfellenglishsc-regular-webfont.d58ba7c3.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}

.ns {
  display: flex;
  flex-wrap: wrap;
  margin: 10vh 0;
}

.ns-image img {
  max-width: 100%;
  width: 400px;
  height: auto;
  flex-direction: column;
  align-items: center;
  border-radius: 6px;
}
.ns .ns-image {
  width: 50%;
  text-align: right;
  padding-right: 40px;
}
.ns .ns-text {
  padding-left: 40px;
  width: 50%;
  align-self: center;
}
@media screen and (max-width: 770px) {
  .ns .ns-image {
    width: 100%;
    text-align: center;

    padding-right: 0px;
  }
  .ns .ns-text {
    padding-left: 0px;
    width: 100%;
    align-self: center;
    text-align: center;
  }
}

.hero {
  background: #07080d;
}

.hero-image {
  max-width: 100%;
  width: 400px;
  height: auto;
  border-radius: 6px;
}

.hero-text {
  justify-content: center;
  text-align: center;
  margin: 50px auto;
}

@media screen and (min-width: 790px) {
  .hero-text {
    width: 55%;
  }
}

.faq > h3 {
  font-size: 29px !important;
  color: var(--primary-color);
}

.faq * {
  text-align: center;
}

/* The actual timeline (the vertical ruler) */
.timeline {
  position: relative;
  max-width: 1200px;
  margin: 50px auto;
}

/* The actual timeline (the vertical ruler) */
.timeline::after {
  content: "";
  position: absolute;
  width: 6px;
  background-color: var(--primary-color);
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -3px;
  border-radius: 10px;
}

/* Container around content */
.container {
  position: relative;
  background-color: inherit;
  width: 50%;
}
@media screen and (min-width: 600px) {
  .container.left {
    padding-right: 10px;
    text-align: right;
  }
  .container.right {
    padding-left: 10px;
    text-align: left;
  }
}
/* The circles on the timeline */
/*
.container::after {
  content: "";
  position: absolute;
  width: 25px;
  height: 25px;
  right: -17px;
  background-color: var(--primary-color);
  border: 4px solid var(--primary-color);
  top: 15px;
  border-radius: 50%;
  z-index: 1;
}
*/
/* Place the container to the left */
.left {
  left: 0;
}

/* Place the container to the right */
.right {
  left: 50%;
}

/* Add arrows to the left container (pointing right) */

/* Fix the circle for containers on the right side */
.right::after {
  left: -16px;
}

/* The actual content */
.content {
  padding: 20px 30px;
  background-color: #fff0;
  color: var(--white-text);
  position: relative;
  border-radius: 6px;
  border: 0 solid var(--primary-color);
}

/* Media queries - Responsive timeline on screens less than 600px wide */
@media screen and (max-width: 600px) {
  /* Place the timelime to the left */
  .timeline::after {
    left: 17px;
  }

  /* Full-width containers */
  .container {
    width: 100%;
    padding-left: 30px;
    padding-bottom: 40px;
  }
  .content {
    padding: 15px 20px;
  }
  /* Make sure that all arrows are pointing leftwards */
  .container::before {
    left: 60px;
    border: medium solid white;
    border-width: 10px 10px 10px 0;
    border-color: transparent white transparent transparent;
  }

  /* Make sure all circles are at the same spot */
  .left::after,
  .right::after {
    left: 0;
  }

  /* Make all right containers behave like the left ones */
  .right {
    left: 0%;
  }
}

:root {
  --font-family: "Noto Regular", sans-serif;
  /*Background*/

  --background: #07080d;
  --navbar-background: #07080d;

  --second-background: #07080d;
  --third-background: #0b152b;
  --white-background: #fdfdfd;

  /*Colors */

  --primary-color: #ebff00;
  --secondary-color: #5886ff;
  --warning-color: rgb(240, 160, 35);
  --dangerous-color: rgb(236, 0, 0);
  --white-text: #fff;
  --black-text: #000;
  --black: #000;

  /*Spacing and sizes*/
  --block-padding: 20px;
  --border-radius: 10px;
  --container-width: 1111px;
  --navbar-height: 120px;
  --navbar-height-m: 24px;

  /*Fonts size*/
  --p: 20px;
  --h1: 44px;
  --h2: 36px;
  --h3: 24px;
  --h4: 20px;
  --h5: 16px;
  --h6: 14px;
}

html,
body {
  padding: 0;
  margin: 0;
  color: white;
  font-family: "Noto Regular", sans-serif;
  font-family: var(--font-family);
}
p {
  line-height: 25px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-block: 0;
  font-family: "Noto Bold", sans-serif;
}
h1 {
  font-weight: 700;
  font-size: 44px;
  font-size: var(--h1);
}
h2 {
  font-weight: 600;
  font-size: 36px;
  font-size: var(--h2);
}
h3,
.h3 {
  font-weight: 600;
  font-size: 24px;
  font-size: var(--h3);
}

h4 {
  font-weight: 600;
  font-size: 20px;
  font-size: var(--h4);
}
h5 {
  font-weight: 600;
  font-size: 16px;
  font-size: var(--h5);
}
h6 {
  font-weight: 600;
  font-size: 14px;
  font-size: var(--h6);
}

p,
strong,
b {
  font-size: 20px;
  font-size: var(--p);
}
a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.section {
  max-width: 1111px;
  max-width: var(--container-width);
  margin: 0px auto;
}
.section-container {
  padding: 40px 20px;
}
.primary-bg {
  background-color: #07080d;
  background-color: var(--background);
}
.secondary-bg {
  background-color: #07080d;
  background-color: var(--second-background);
}

.cta-bg {
  background-color: #ebff00;
  background-color: var(--primary-color);
}

/*HOMES IMAGES*/

.heading {
  text-align: center;
  padding: 40px 0;
}

.logo-text {
  font-family: "IM";
  font-weight: bold;
  font-style: italic;
  color: #f4d35e;
  font-size: 60px;
}
.social-icons {
  display: inline-block;
  color: rgb(236, 218, 218);
}

@media screen and (max-width: 700px) {
  .social-icons {
    margin-top: 20px;
  }
}
.social-icons a,
.social-icons a:hover {
  margin: 40px;
  text-decoration: none;
  text-transform: none;
  font-size: 40px;
}
.social-icons a {
  color: rgb(255, 255, 255);
}

.social-icons a:hover {
  color: #ebff00;
  color: var(--primary-color);
}

/* The actual timeline (the vertical ruler) */
.timeline {
  position: relative;
  max-width: 1200px;
  margin: 50px auto;
}

/* The actual timeline (the vertical ruler) */
.timeline::after {
  content: "";
  position: absolute;
  width: 6px;
  background-color: var(--primary-color);
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -3px;
  border-radius: 10px;
}

/* Container around content */
.container {
  position: relative;
  background-color: inherit;
  width: 50%;
}
@media screen and (min-width: 600px) {
  .container.left {
    padding-right: 10px;
    text-align: right;
  }
  .container.right {
    padding-left: 10px;
    text-align: left;
  }
}
/* The circles on the timeline */
/*
.container::after {
  content: "";
  position: absolute;
  width: 25px;
  height: 25px;
  right: -17px;
  background-color: var(--primary-color);
  border: 4px solid var(--primary-color);
  top: 15px;
  border-radius: 50%;
  z-index: 1;
}
*/
/* Place the container to the left */
.left {
  left: 0;
}

/* Place the container to the right */
.right {
  left: 50%;
}

/* Add arrows to the left container (pointing right) */

/* Fix the circle for containers on the right side */
.right::after {
  left: -16px;
}

/* The actual content */
.content {
  padding: 20px 30px;
  background-color: #fff0;
  color: var(--white-text);
  position: relative;
  border-radius: 6px;
  border: 0 solid var(--primary-color);
}

/* Media queries - Responsive timeline on screens less than 600px wide */
@media screen and (max-width: 600px) {
  /* Place the timelime to the left */
  .timeline::after {
    left: 17px;
  }

  /* Full-width containers */
  .container {
    width: 100%;
    padding-left: 30px;
    padding-bottom: 40px;
  }
  .content {
    padding: 15px 20px;
  }
  /* Make sure that all arrows are pointing leftwards */
  .container::before {
    left: 60px;
    border: medium solid white;
    border-width: 10px 10px 10px 0;
    border-color: transparent white transparent transparent;
  }

  /* Make sure all circles are at the same spot */
  .left::after,
  .right::after {
    left: 0;
  }

  /* Make all right containers behave like the left ones */
  .right {
    left: 0%;
  }
}



@font-face {
  font-family: "Noto Bold";
  src: url(/static/media/notosans-bold-webfont.7b2ff0f6.woff2) format("woff2"),
    url(/static/media/notosans-bold-webfont.751b2601.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Noto Regular";
  src: url(/static/media/notosans-regular-webfont.37f3c0a5.woff2) format("woff2"),
    url(/static/media/notosans-regular-webfont.468c2af3.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}

/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on October 20, 2021 */

@font-face {
  font-family: "IM";
  src: url(/static/media/imfellenglishsc-regular-webfont.3e37a892.woff2) format("woff2"),
    url(/static/media/imfellenglishsc-regular-webfont.d58ba7c3.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}

.NotSame_ns__34XJI {
  display: flex;
  flex-wrap: wrap;
  margin: 10vh 0;
}

.NotSame_ns-image__wlslk img {
  max-width: 100%;
  width: 400px;
  height: auto;
  flex-direction: column;
  align-items: center;
  border-radius: 6px;
}
.NotSame_ns__34XJI .NotSame_ns-image__wlslk {
  width: 50%;
  text-align: right;
  padding-right: 40px;
}
.NotSame_ns__34XJI .NotSame_ns-text__35oGK {
  padding-left: 40px;
  width: 50%;
  align-self: center;
}
@media screen and (max-width: 770px) {
  .NotSame_ns__34XJI .NotSame_ns-image__wlslk {
    width: 100%;
    text-align: center;

    padding-right: 0px;
  }
  .NotSame_ns__34XJI .NotSame_ns-text__35oGK {
    padding-left: 0px;
    width: 100%;
    align-self: center;
    text-align: center;
  }
}

.Hero_hero__3RcUq {
  background: #07080d;
}

.Hero_hero-image__1ted8 {
  max-width: 100%;
  width: 400px;
  height: auto;
  border-radius: 6px;
}

.Hero_hero-text__3lkDn {
  justify-content: center;
  text-align: center;
  margin: 50px auto;
}

@media screen and (min-width: 790px) {
  .Hero_hero-text__3lkDn {
    width: 55%;
  }
}

.Faq_faq__3fDCl > h3 {
  font-size: 29px !important;
  color: var(--primary-color);
}

.Faq_faq__3fDCl * {
  text-align: center;
}

/* The actual timeline (the vertical ruler) */
.Roadmap_timeline__vbNQg {
  position: relative;
  max-width: 1200px;
  margin: 50px auto;
}

/* The actual timeline (the vertical ruler) */
.Roadmap_timeline__vbNQg::after {
  content: "";
  position: absolute;
  width: 6px;
  background-color: var(--primary-color);
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -3px;
  border-radius: 10px;
}

/* Container around content */
.Roadmap_container__2V3uX {
  position: relative;
  background-color: inherit;
  width: 50%;
}
@media screen and (min-width: 600px) {
  .Roadmap_container__2V3uX.Roadmap_left__3d3LT {
    padding-right: 10px;
    text-align: right;
  }
  .Roadmap_container__2V3uX.Roadmap_right__HUR7e {
    padding-left: 10px;
    text-align: left;
  }
}
/* The circles on the timeline */
/*
.container::after {
  content: "";
  position: absolute;
  width: 25px;
  height: 25px;
  right: -17px;
  background-color: var(--primary-color);
  border: 4px solid var(--primary-color);
  top: 15px;
  border-radius: 50%;
  z-index: 1;
}
*/
/* Place the container to the left */
.Roadmap_left__3d3LT {
  left: 0;
}

/* Place the container to the right */
.Roadmap_right__HUR7e {
  left: 50%;
}

/* Add arrows to the left container (pointing right) */

/* Fix the circle for containers on the right side */
.Roadmap_right__HUR7e::after {
  left: -16px;
}

/* The actual content */
.Roadmap_content__1Di-t {
  padding: 20px 30px;
  background-color: #fff0;
  color: var(--white-text);
  position: relative;
  border-radius: 6px;
  border: 0 solid var(--primary-color);
}

/* Media queries - Responsive timeline on screens less than 600px wide */
@media screen and (max-width: 600px) {
  /* Place the timelime to the left */
  .Roadmap_timeline__vbNQg::after {
    left: 17px;
  }

  /* Full-width containers */
  .Roadmap_container__2V3uX {
    width: 100%;
    padding-left: 30px;
    padding-bottom: 40px;
  }
  .Roadmap_content__1Di-t {
    padding: 15px 20px;
  }
  /* Make sure that all arrows are pointing leftwards */
  .Roadmap_container__2V3uX::before {
    left: 60px;
    border: medium solid white;
    border-width: 10px 10px 10px 0;
    border-color: transparent white transparent transparent;
  }

  /* Make sure all circles are at the same spot */
  .Roadmap_left__3d3LT::after,
  .Roadmap_right__HUR7e::after {
    left: 0;
  }

  /* Make all right containers behave like the left ones */
  .Roadmap_right__HUR7e {
    left: 0%;
  }
}

:root {
  --font-family: "Noto Regular", sans-serif;
  /*Background*/

  --background: #07080d;
  --navbar-background: #07080d;

  --second-background: #07080d;
  --third-background: #0b152b;
  --white-background: #fdfdfd;

  /*Colors */

  --primary-color: #ebff00;
  --secondary-color: #5886ff;
  --warning-color: rgb(240, 160, 35);
  --dangerous-color: rgb(236, 0, 0);
  --white-text: #fff;
  --black-text: #000;
  --black: #000;

  /*Spacing and sizes*/
  --block-padding: 20px;
  --border-radius: 10px;
  --container-width: 1111px;
  --navbar-height: 120px;
  --navbar-height-m: 24px;

  /*Fonts size*/
  --p: 20px;
  --h1: 44px;
  --h2: 36px;
  --h3: 24px;
  --h4: 20px;
  --h5: 16px;
  --h6: 14px;
}

html,
body {
  padding: 0;
  margin: 0;
  color: white;
  font-family: "Noto Regular", sans-serif;
  font-family: var(--font-family);
}
p {
  line-height: 25px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-block: 0;
  font-family: "Noto Bold", sans-serif;
}
h1 {
  font-weight: 700;
  font-size: 44px;
  font-size: var(--h1);
}
h2 {
  font-weight: 600;
  font-size: 36px;
  font-size: var(--h2);
}
h3,
.global_h3__2fRn2 {
  font-weight: 600;
  font-size: 24px;
  font-size: var(--h3);
}

h4 {
  font-weight: 600;
  font-size: 20px;
  font-size: var(--h4);
}
h5 {
  font-weight: 600;
  font-size: 16px;
  font-size: var(--h5);
}
h6 {
  font-weight: 600;
  font-size: 14px;
  font-size: var(--h6);
}

p,
strong,
b {
  font-size: 20px;
  font-size: var(--p);
}
a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.global_section__eZZ5C {
  max-width: 1111px;
  max-width: var(--container-width);
  margin: 0px auto;
}
.global_section-container__1zigG {
  padding: 40px 20px;
}
.global_primary-bg__2cQLp {
  background-color: #07080d;
  background-color: var(--background);
}
.global_secondary-bg__3q7dB {
  background-color: #07080d;
  background-color: var(--second-background);
}

.global_cta-bg__aTqEy {
  background-color: #ebff00;
  background-color: var(--primary-color);
}

/*HOMES IMAGES*/

.global_heading__2YVJQ {
  text-align: center;
  padding: 40px 0;
}

.global_logo-text__2gQ26 {
  font-family: "IM";
  font-weight: bold;
  font-style: italic;
  color: #f4d35e;
  font-size: 60px;
}
.global_social-icons__3I5XW {
  display: inline-block;
  color: rgb(236, 218, 218);
}

@media screen and (max-width: 700px) {
  .global_social-icons__3I5XW {
    margin-top: 20px;
  }
}
.global_social-icons__3I5XW a,
.global_social-icons__3I5XW a:hover {
  margin: 40px;
  text-decoration: none;
  text-transform: none;
  font-size: 40px;
}
.global_social-icons__3I5XW a {
  color: rgb(255, 255, 255);
}

.global_social-icons__3I5XW a:hover {
  color: #ebff00;
  color: var(--primary-color);
}

.Navbar_navbar-container__1r-7b {
  width: 100%;
  padding: 0 20px;
  background-color: var(--navbar-background);
}

.Navbar_navbar__1HfJ- {
  display: flex;
  align-items: center;
  justify-content: center;

  max-width: var(--container-width);
  height: var(--navbar-height);
  margin: 0 20px;
  margin: 0 auto;
}

.Navbar_site-title__2k9ts {
  color: var(--primary-color);
  font-family: "IM Fell English SC", serif;
  font-size: 30px;
  line-height: 40px;
  padding: 10px;
}
@media screen and (min-width: 700px) {
  .Navbar_site-title__2k9ts {
    font-size: 40px;
  }
}
.Navbar_menu__2EO2- {
  color: #fff;
  margin-left: 40px;
}

.Navbar_menu-item__3RqIU {
  margin-right: 20px;
  display: inline-block;
}

.Footer_navbar-container__2Korz {
  width: 100%;
  background: linear-gradient(0deg, rgba(6, 8, 15, 1) 50%, var(--background));

  padding: 0 20px;
}

.Footer_navbar__2DH50 {
  max-width: var(--container-width);
  display: flex;
  height: 300px;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.Footer_site-title__2_awY {
  color: var(--primary-color);
  font-family: Josefin Sans;
  font-style: italic;
  font-weight: bold;
  font-size: 30px;
  line-height: 40px;
  align-items: center;
}

.Footer_social__1xBi9 {
  color: #fff;
  margin-left: 40px;
  display: inline-block;
}

.Footer_social__1xBi9 > * {
  margin-right: 20px;
  display: inline-block;
}

