.navbar-container {
  width: 100%;
  background: linear-gradient(0deg, rgba(6, 8, 15, 1) 50%, var(--background));

  padding: 0 20px;
}

.navbar {
  max-width: var(--container-width);
  display: flex;
  height: 300px;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.site-title {
  color: var(--primary-color);
  font-family: Josefin Sans;
  font-style: italic;
  font-weight: bold;
  font-size: 30px;
  line-height: 40px;
  align-items: center;
}

.social {
  color: #fff;
  margin-left: 40px;
  display: inline-block;
}

.social > * {
  margin-right: 20px;
  display: inline-block;
}
