.hero {
  background: #07080d;
}

.hero-image {
  max-width: 100%;
  width: 400px;
  height: auto;
  border-radius: 6px;
}

.hero-text {
  justify-content: center;
  text-align: center;
  margin: 50px auto;
}

@media screen and (min-width: 790px) {
  .hero-text {
    width: 55%;
  }
}
