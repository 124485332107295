@font-face {
  font-family: "Noto Bold";
  src: url("notosans-bold-webfont.woff2") format("woff2"),
    url("notosans-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Noto Regular";
  src: url("notosans-regular-webfont.woff2") format("woff2"),
    url("notosans-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
