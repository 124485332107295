@import url("global.css");
@import url("https://fonts.googleapis.com/css2?family=IM+Fell+English+SC&display=swap");
.navbar-container {
  width: 100%;
  padding: 0 20px;
  background-color: var(--navbar-background);
}

.navbar {
  display: flex;
  align-items: center;
  justify-content: center;

  max-width: var(--container-width);
  height: var(--navbar-height);
  margin: 0 20px;
  margin: 0 auto;
}

.site-title {
  color: var(--primary-color);
  font-family: "IM Fell English SC", serif;
  font-size: 30px;
  line-height: 40px;
  padding: 10px;
}
@media screen and (min-width: 700px) {
  .site-title {
    font-size: 40px;
  }
}
.menu {
  color: #fff;
  margin-left: 40px;
}

.menu-item {
  margin-right: 20px;
  display: inline-block;
}
